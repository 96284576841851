
.auth-btn {
    background: #253a7d;
    color: #fff;
    font-size: 15px;
    line-height: 1.3;
    padding: 10px 15px !important;
    margin-left: 10px;
    text-transform: capitalize;
    border-radius: 4px;
    display: inline-block;
}
.auth-btn:hover{ color: #fff; background: #32a963;}
.banner-down{
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    color: #fff;
    width: 35px;
    height: 55px;
    border: 3px solid #0B9B48;
    border-radius: 20px;
}
.down-arrow-inner{
    width: 4px;
    height: 12px;
    background: #0b9b48;
    display: block;
    margin: 0 auto;
    position: relative;
    top: 8px;
    border-radius: 20px;
    animation: dot 1s ease-in infinite;
}
@keyframes dot{
    0%{
        top: 8px;
    }
    50%{
        top: 12px;
    }
    100%{
        top: 8px;
    }
}
.menu-dot{
    width: 7px;
    height: 7px;
    margin: 0 auto;
    border-radius: 50%;
    display: block;
    background: #e06425;
}
.nav-item.active .menu-dot{
    background:#32a963;
}
.menu-dot.active{
    background:#32a963;
}
.max-with630{
    max-width: 630px;
    margin: 0 auto;
}
.heading-section img{
    max-width: 200px;
}
.card-text-body .card-text{
    margin-bottom: 20px;
}
.footer-about p{
    color: #fff;
}
  /* width */
  ::-webkit-scrollbar {
    width:8px;
    border-radius: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
    border-radius: 10px;
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background-color: rgba(14, 55, 104,0.11);
    border-radius: 5px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #094285; 
  }
  #are-you-a{
    padding: 70px 0px 60px !important; 
  }
  .margin-top100{
   margin-top: 100px;
  }
  .breadcum{
    background: #253a7d;
    position: relative;
    padding: 40px 0px;
  }
  .breadcum h1{
    margin: 0;
    color: #fff;
  }
  .section-padding{
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .auth-form{
    width: 100%;
    max-width: 450px;
    margin: 0 auto;
}
.btn-primary{
    background-color: #253A7D !important;
}
.full-height{
    height: auto;
    min-height: calc(100vh - 471px);
}
.align-item{
    display: flex;
    align-items: center;
}
input, select, textarea, label, a, .btn{
    font-family: 'Carrois Gothic', sans-serif;
}
input, select, textarea, .btn{
    border-radius:0px !important;
}
.btn{
 border: 0px;
}
input:focus, select:focus, textarea:focus{
    outline: none;
    border:1px solid red;
}
.profile-box{
    width: 100%;
    max-width: 550px;
    margin: 0 auto;
}
.card-title{
    margin-bottom: 0px;
}
.course-title{
    color: #464646 !important;
    font-family: 'Carrois Gothic', sans-serif;
}
.loader-wrapper{
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
}
.loader-wrapper img{
    margin: 0 auto;
    max-width: 80px;
}
.data-loader-wrapper {
    margin:0 auto;
}
.mgtop0{
    margin-top: 0px;
}
.mgbot0{
    margin-bottom: 0px;
}
.pdbt0{
    padding-bottom: 0px !important;
}
video, .react-player__preview{
    min-height: 200px !important;
}
.preview_text{
    padding: 10px 15px;
}
.preview_text h3{
    color: #000;
    font-size: 16px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 15px;
}
.player-wrapper{
    padding: 10px;
    background: #fff;
    border-radius: 5px 5px 0px 0px;
    box-shadow: 0px -5px 14px rgba(0,0,0,0.3);
}
.section-padding-sm{
    padding:20px 10px;
}
.course-description{
    padding: 10px 0px;
    text-align: justify;
}
.ewcqji {
   
    margin: 10px 10px 10px 0px !important;
   
}
.review-title{
    text-transform: uppercase;
    font-size: 18px;
    text-align: left;
    margin-bottom: 0px;
}
.pd0{
    padding: 0px 15px !important;
}
.pdt90{
    padding-top:90px !important;
}
.course-content .courses-title h5{
    font-size: 16px;
    margin: 0;
    padding: 0;
}
.course-content .courses-title p{
    font-size: 14px;
    margin: 0;
    padding: 0;
}
.course-list-item{
    margin: 0;
    padding: 0px 0px;
    list-style: none;
}
.course-list-item li{
    border-bottom: 1px solid rgba(0,0,0,0.1);
    padding: 10px 0px;
}
.course-list-item li a{
    display: block;
    cursor: pointer;
    color: #000;
}
.play_status{
    color: #253a7d;
    font-size: 12px;
}
.course-list-item li:nth-last-child(1){
    border-bottom: 0px;
}
.review-box{
    width:100%;
    padding: 0px 20px;
}
.main-section{
    min-height: calc(100vh - 491px);
    width: 100%;
}
.video-not-found{
    text-align: center;
    padding: 20px 0px;
    font-size: 16px;
}
@media (max-width: 1199px){
    button.menu {
        display: inline-block !important;
    }
    
}
@media (max-width:768px){
    .auth-btn { 
        padding: 9px 10px !important;  
    }
    .mb-center{
      text-align: center !important;
    }
    .mb-hide{
        display: none !important;
    }
    .footer-mb-center{
        display: block;
        text-align: center;
        width: 100%; 
    }
    .nav-item{
        position: relative;
    }
    .menu-dot {
        position: absolute;
        right: 0;
        bottom: 18px;
    }
}
@media (max-width:420px){
    .auth-btn {
        padding: 9px 10px !important;
        font-size: 12px;
        margin-top: 5px;
    }
}
