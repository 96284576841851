@charset "UTF-8";


.body{
	font-family: 'Carrois Gothic', sans-serif;
	font-size:16px;
    }

    div, img, button, input{transition: all 250ms ease-in;}

    .dropdown .dropdown-menu {
      
        transform:scale(1.0); 
        animation: dropDown 300ms ease-in;
     }

     .row-flex {
        display: flex;
        flex-wrap: wrap;
      }
    
      .error{
        color: red;
        font-size: 12px;
      }
      .big-alert{
        font-size: 16px !important; 
      }
.form-loader{
    width: 100%;
    max-width: 50px;
}
.caret-hide:after { content: none !important; }
.auth-btn.caret-hide{
    border: 0;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: 0 !important;
}
   

    .onhover.dropdown:hover .dropdown-menu {
        display: block;
        margin-top: 0;
        animation: dropDown 300ms ease-out;
      
     }
     @keyframes dropDown{
         0% {opacity: 0;  }
         100% {opacity: 1; }
     }

     h1, h2, h3, h4{ font-family: 'Yu Gothic';  font-weight: 700;}
  h5{font-family: 'Yu Gothic';  font-weight: 700;}
  img{width: 100%;}

   button:focus, input{ outline: none !important;}
    a:hover, a:focus{text-decoration: none; transition: all 200ms ease-out;}
	p{
        font-family: 'Carrois Gothic', sans-serif;
        font-size: 15px;
        color:#3c3c3c;
        line-height: 1.2;
        margin-bottom: 0.7rem;
	}
.mr-tp-25{ margin-top:25px;}
    
    /* header*/
.navbar-brand  span{
	color: #fed136;
	font-size:25px;font-weight:700;letter-spacing:0.1em;
    
}
.navbar-brand {
	color: #fff;
    letter-spacing:0.1em;
    padding-top: .5125rem;
    padding-bottom: .4125rem;
}

.navbar {
    padding: 1rem 1rem;
}
header .navbar-nav .nav-item .nav-link{
   font-family: 'Carrois Gothic', sans-serif;  font-weight: 500;
	padding: 0.5em 0.7em 0px;
	font-size: 16px;
    font-weight: 500;
    letter-spacing: 1px;
    color: #464646;
  
   position: relative;
}

.login-li{display: flex;
    align-items: center;}

.login-link{display: block;

    color: #1a0747 !important;
  padding:8px 17px !important;
    border-radius: 20px;
    margin-left: 10px;
    font-size: 12px;
    background-color: #fff;}


    li.user .login-link{width : 40px !important; height: 40px !important; line-height: 40px !important; 
        padding: 0px !important ; text-align: center;}
        li.user .login-link::after{display: none;}
    


.navbar-nav .nav-item .nav-link span{ display: inline-block; width: 1px; background-color: #a9b7ca;
     position: absolute;right: 0px; height: 10px; top: 50%; margin-top: -5px;}


.navbar-expand-md .navbar-nav .dropdown-menu{
    left: -62%;
    margin-top: 14px;
    border-radius: 5px;
    background-color:#0b9b48;
    box-shadow: 0px 11px 8px 0px rgba(27, 66, 113, 0.06); border: 0;
   
}
.dropdown-menu{
    left: -44px;
    margin-top: 14px;
    border-radius: 5px;
    background-color:#0b9b48;
    box-shadow: 0px 11px 8px 0px rgba(27, 66, 113, 0.06); border: 0;
   
}
.dropdown-menu::before{
    height: 20px; width: 20px; position: absolute; display: inline-block;
    background-color: #0b9b48; top: -10px; content: ''; left: 50%; margin-left: -10px;
    transform: rotate(45deg); z-index: -1;
}
.navbar-expand-md .navbar-nav .user .dropdown-menu {
    left: -50%;
    margin-left: -100%;
    margin-top: 14px;
    border-radius: 5px;
    background-color: #1d084f;
    box-shadow: 0px 11px 8px 0px rgba(27, 66, 113, 0.06);
    border: 0;
}
.navbar-expand-md .navbar-nav .dropdown-menu::before{
    height: 20px; width: 20px; position: absolute; display: inline-block;
    background-color: #0b9b48; top: -10px; content: ''; left: 50%; margin-left: -10px;
    transform: rotate(45deg); z-index: -1;
}
.dropdown-item{text-align: left;  font-size: 14px;
    font-family: 'atami';
    color: #fff;
    text-transform: uppercase;
    line-height: 1.714;}
.double-hr{border: 0; margin-top: 0.2rem; margin-bottom: 0.2rem;
     border-bottom:1px solid rgba(255,255,255,0.1); border-top:1px solid rgba(0, 0, 0, 0.5);}

.dropdown-item:hover{ color:#00f0ff; background-color: transparent;}
nav{-webkit-transition: padding-top .3s,padding-bottom .3s;
    -moz-transition: padding-top .3s,padding-bottom .3s;
    transition: padding-top .3s,padding-bottom .3s;
    border: none;
    }
    .dropdown-toggle::after {
        margin-left: 0em;
        vertical-align: 0.1em;
    }
.navbar-dark .navbar-nav .active>.nav-link, .navbar-dark .navbar-nav, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .show>.nav-link {
        color:#464646;
    }
	.navbar-dark .navbar-nav, .navbar-dark .navbar-nav .nav-link:hover {
        color:#32a963;
    }

    .page-login .navbar{background-color: #eef1f5;}

 .shrink {
    padding-top: 0;
    padding-bottom: 0;
    background: #fff;
    box-shadow: 5px 5px 15px rgba(0,0,0,0.1);
}
.navbar-brand img{ width:180px; }
.shrink .navbar-brand img {
    width: 160px;
}

button.menu{ display: none; vertical-align:top; position:relative;  height:38px; width:60px; background-color:transparent; border:0; z-index:11; text-align:center;}

button.menu:hover span{ background-color:#0b9b48;}

button.menu2:hover span{ background-color:#0b9b48; }
button.menu span{ display:block; *display:block; padding:0px 0px; position:relative; width:25px; height:2px; margin:5px; background-color:#0b9b48; margin-left:auto; 
margin-right:auto;  transition:all 400ms ease-out;}

button.menu2 span{   margin:0px; background-color:#fff; margin-left:auto; width:25px; height:2px; margin-right: auto;}
button.menu2 span:nth-child(1){ transform:rotate(45deg); }
button.menu2 span:nth-child(2){ display:none; }
button.menu2 span:nth-child(3){ transform:rotate(-45deg); margin-top:-2px; }

/* banner */
.home-banner{
    height: auto;
    min-height:100vh;
   padding-top:80px; padding-bottom: 30px;
    background-color: #0a4d72;
    
background-image:linear-gradient(0deg, #F6F5F5FC 0%, #F6F5F59E 15%, #F6F5F5E6 76%, #F6F5F5DB 100%), url(../images/banner.jpg); 
background-position:center center;
background-size: 100% 100%, cover;
background-repeat: no-repeat;
display: flex; 

    
}
.innterPageBanner{ 

position: relative;
background-color: #1a0747;
}

.pagebannerWraper{overflow: hidden; text-align:left;}
.pagebannerWraper img{ width: 100%; min-width:600px;}


.banner-image{ width: 100%; max-width: 422px; }
.mobileImge{display: none;}

.itm-aln{ align-items: center; display: flex; width: 100%;}

.innterPageBanner .itm-aln{top: 0;
    width: 100%;
    height: 100%;
    padding-top: 40px; position: absolute;}

.heading-big{
    font-size: 45px;
    text-transform: uppercase;
    color: #0B9B48;
    line-height: 1.3;
    font-weight: 600;
    margin-bottom: 20px;
}

.home-banner p{color:#464646ab; font-size: 17px; margin-bottom: 20px;}
    .loginInput input{
        height: 55px;   border-radius: 2px;  background-color: rgba(255, 255, 255, 0.051);
        border: 0;
        padding:0px 20px;
       width: 100%;
       max-width: 300px;
       margin-right:8px;
       display: inline-block;
       color: #fff;
    }

.globbal-link{  border-radius: 4px;
    display: inline-block;
    background-color: #253a7d;
   font-family: 'Carrois Gothic', sans-serif; font-weight: 500;
    box-shadow: 0px 9px 13px 0px rgba(24, 24, 25, 0.11);
    color: #fff;
    font-size: 15px;
    padding: 11px 18px; text-transform: capitalize;
}
.white-link{ background-color: #fff;
    background-image: none;
    color: #253a7d;
    margin-left: 10px;}
.globbal-link:hover{background-color: #0B9B48; background-image: none; color: #fff; }


/*sideContact*/
.call-box{ position: relative;}

.box{   border: 1px solid rgba(255, 255, 255, 0.250);     width: max-content;
    border-radius: 5px;  min-width: 330px;
    background-color: rgba(0, 0, 0, 0.25); color:#eae9e9;     padding: 20px 15px; font-size: 20px; font-family: 'Roboto Condensed', sans-serif;}
    .box:nth-child(1){margin-bottom: 15px; }

    .box i{display:inline-block; font-size: 40px;     vertical-align: middle;}
    .box span{display:inline-block; padding-left: 10px; line-height: 1.1; margin-left: 10px; border-left: 1px solid rgba(255,255,255,0.1);      vertical-align: middle; }
    .box span b{display: block;  letter-spacing: 1px;}

    .loginInput input[type=submit]{ width: 70px; background-color: #5f0fcd; color: transparent; 
      
        background-repeat: no-repeat; background-position: center center;
    }
    .loginInput input[type=submit]:hover{ background-color: #01c7d3;}

    .loginInput input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #c3adf7;
        opacity: 1; /* Firefox */
      }

      .formLnks{ font-family: 'atami'; color: rgba(255, 255, 255, 0.15); margin-top:5px;}
      .formLnks a{ color: #a179fd ; display: inline-block; margin:15px 5px; font-size: 18px;}
      .formLnks a:hover{ color: #00f0ff;}

.dreamGreen{
    background-color: #0B9B48;
    color: #fff;
    padding: 3px 15px;
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 15px;
}

.siderLine{ position: relative; padding-left: 20px; max-width: 655px; margin-top: 25px;}
.siderLine span{position: absolute; height: 100%; left: 0; top: 0; width: 4px; border-radius: 2px; background-color: #163e6e; }

/* links */

.my-links{ display: inline-block;   box-shadow: 0px 11px 8px 0px rgba(27, 66, 113, 0.06); border-radius: 26px;
    font-size: 16px;
    font-family: 'atami-bold';
    line-height: 1.2;
    padding: 17px 30px;
}
.my-links.blue{background-color: #163e6e;   color: rgb(255, 255, 255); margin-right: 20px;}
.my-links.white{background-color: #fff;   color:#163e6e; }
.my-links.blue:hover, .my-links.white:hover{background-color: #00f0ff; color: #fff;}




/*footer */
footer{position: relative; width: 100%; bottom: 0; background-color: #253A7D}
.pageBody footer{ position: relative; background-color: #053d59; background-image: none !important;}  
    .footerCon{ line-height: 40px; width: 100%;
         padding:10px 0px;  position: relative;  color: #9bbaca; font-size: 13px;}
         .footerCon a{ color:#0b9b48;}
    .socil-media{ height: 35px; font-size: 15px; width: 35px; text-align: center;
         color: #fff; background-color: rgba(0, 0, 0, 0.2);
    line-height: 35px;  margin: 0px 1px; display: inline-block; border-radius: 100%;
    }
    .socil-media:hover{ background-color:#fff;     color:#0b9b48;}

/*loginPage   body.page-login*/
body.page-login .itm-aln{ align-items: flex-end;
}
.rspnvAlingnright{ text-align: right !important; }
.loginBox{ width:360px;
    text-align: center;
padding:45px;
padding-bottom:20px;
display: inline-block;
border-radius: 15px 15px 0px 0px;
background-color: #f0f1f1;
box-shadow: -0.157px -2.996px 59px 0px rgba(0, 0, 0, 0.22);
}

.loginBox img{ width: 150px; margin-bottom: 20px;}
.loginBox .icon{ height: 30px;
    display: inline-block;
    vertical-align: middle;
    line-height: 30px;
    width: 40px;
    text-align: center;
    border-right: 1px solid #e6e6e6;
    color: #163e6e;
    font-size: 21px; }
    .inputArea{ border: 0;
        width: calc(100% - 41px);
        height: 30px;
        padding: 0px 11px; color: #163e6e; }

.inputBox{ border-radius: 5px;
    background-color: rgb(255, 255, 255);
    box-shadow: 5.333px 9.621px 10px 0px rgba(0, 0, 0, 0.04); 
padding:7px;
margin-bottom: 10px;
}

.loginBox p{  font-size: 14px;
    color: rgb(50, 50, 50);
    line-height:1.0; margin: 15px 0px;}
   a.textLink{ color: #163d6d;}
   a.textLink:hover{color:#00f0ff;}

   .loginBTN, .gloginBTN{display: block; text-align: center;
    border-radius: 5px;
    background-color: rgb(22, 62, 110);
    box-shadow: 5.333px 9.621px 10px 0px rgba(0, 0, 0, 0.04);
    height: 44px;
    width: 100%;
    margin-bottom: 10px;
    color: #fff;
    border: 0;
}
.gloginBTN{background-color: #eb4132;}
.loginBTN:hover, .gloginBTN:hover{ background-color: #00f0ff;}
.heading-section{
    margin-bottom: 10px;
}
.main-heading{
    font-size: 26px;
    color: #253A7D;
    text-transform: uppercase;
    margin-bottom: 0px;
}

.headingtype2 .main-heading{display: inline-block; position: relative; margin: 0; margin-right: 10px; 
    padding-right: 15px; vertical-align: sub; margin-bottom: 15px; } 
.headingtype2 .main-heading::after{content: ""; background: rgb(15,137,206);
    background: linear-gradient(0deg, rgba(15,137,206,1) 0%, rgba(15,208,111,1) 100%);
     position: absolute; height: 100%; width: 3px; right: 0; top: 0;  }
     .headingtype2 i {color:#3c3c3c; font-size: 16px; font-family: 'Roboto Condensed', sans-serif;}

.content-section{padding: 60px 0px; }
.gradiant{background: rgb(15,137,206);
    background: linear-gradient(90deg, rgba(15,137,206,1) 0%, rgba(15,208,111,1) 100%);
    display: block; border: 0;
    width: 29px;
    height: 3px;
    margin-bottom: 0px;
    display: inline-block;
}
    


/* succces-story*/

.succces-story{ position: relative; overflow: hidden; background-color: #fff;}
.succces-story .container{position: relative; z-index: 2;}
.succces-story:before{ position: absolute; width: 50%; top: 0; left: -21%; height: 100%; background-color: #eeeeee;
content: "";
z-index: 1;
}
.imgcol .gradiant {
    margin: auto;
    display: block;
    height: 8px;
    width: 84px;
}
.imgWithShadow{  border-radius: 4px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.16); width: 100%;}
    #testimonials{margin-top: 0px; }

    .content-middle{ position: relative;}
    .content-middle  .content-box{ min-height: 100%; width:100%; display: flex; align-items: center; }
    .content-middle p{width: 100%;}
    .storyName{
        font-size: 14px;
       font-family: 'Carrois Gothic', sans-serif;
        font-weight: 500;
        color: rgb(7, 112, 145);
        line-height: 1.857;
        display: block;
        text-align: left;
    }
    .storyName b{font-weight: 700;   font-size: 20px;}

    /*value Services*/
    [class*="col-"] {
        margin-top: 15px;
        margin-bottom: 15px;
      }
    .are-you{background-color:#F6F5F5;}
    .mystyleC.card{  border: 0;   border-radius: 5px;
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 5px 10px 0px rgba(24, 24, 25, 0.1); overflow: hidden;  height: 100%; }
        .row-flex {
            display: flex;
            flex-wrap: wrap;
          }
          .mystyleC .card-body {
            padding: 1.0rem 1rem 1.5rem;
        }
        .mystyleC .card-body-first{ padding-bottom: 0px; }
        .card-thumb{
            position: relative;
            text-align: center;
        }
        .overlay{
            position: absolute;
            width: 100%;
            background: transparent linear-gradient(180deg, #253A7D00 0%, #253A7DDE 100%) 0% 0% no-repeat padding-box;
            height: 100%;
            top: 0;
            display: flex;
            align-items: flex-end;
        }
        .mystyleC .card-title { 
            width: 100%;
            color: #fff;
        }
       .title-sec{
        width: 100%; 
        margin-bottom: 15px;
       }
       .title-sec h5{
       margin-bottom: 0px;
       }
      .title-sec img{
        max-width: 140px;
      }
    .h-lin::after{ content: ""; height:4px; width:50px; display: block; background: rgb(15,137,206);
    background: linear-gradient(90deg, rgba(15,137,206,1) 0%, rgba(15,208,111,1) 100%);     margin-top: 10px;}

    .card-link{
        background-color: #253a7d;
        font-family: 'Carrois Gothic', sans-serif;
        font-weight: 500;
        box-shadow: 0px 9px 13px 0px rgba(24, 24, 25, 0.11);
        color: #fff;
        font-size: 15px;
        padding: 11px 18px;
        border-radius: 4px;
        text-transform: capitalize;}
        .card-link:hover{color: #fff;
            background: #32a963;}

        /* homepage blog */
        .blogsSec{ background-color: #fff; }
        .blogs{text-align: center; }
        .imagehover{position: relative; border-radius: 5px; overflow: hidden;}
        .imagehover span{ background: rgb(15,137,206);
            background: linear-gradient(0deg, rgba(15,137,206,0.7) 0%, rgba(15,208,111,0.7) 100%);
        position: absolute; height: 100%;
        width: 100%; top: 0; left: 0;
        opacity: 0; transition: all 400ms ease-in;
        }
        .hoverblog:hover .imagehover span{ opacity: 1;}

        .imagehover span::after, .imagehover span::before{content:""; background-color:#fff;
             display: block; position: absolute;}
        .imagehover span::after{height: 40px; width: 1px; left: 50%;
                 top: 50%; margin-top: -20px;}
        .imagehover span::before{height: 1px; width: 40px; left: 50%;
                    top: 50%; margin-left: -20px; }

        .datepach{ display: inline-block;font-family: 'Carrois Gothic', sans-serif; 
        background-color: rgb(8, 69, 99); color:#fff; padding: 10px 25px; border-radius: 3px;
         margin-top: -25px; top:-13px; position: relative; }

       
        /* homepage team */  
        .team{background-color: #f9f9f9;
        }
        
        .teamProfle{ border-radius: 3px;
            background-color: rgb(14, 107, 135);
            position: relative;
            padding: 8px 13px;
            color: #fff;
            font-size: 20px;
            margin-top: -10px;
            top: -14px; 
        
            display: inline-block;
            width: fit-content;
            margin-left: auto;
            margin-right:auto;
        }
        #team{margin-top: 15px;}
        .team .card-text{margin-bottom: 5px;}
        .team .card-title{color:#084563; margin-bottom:4px;}
        .colorG{color:#14ba67; }
   

            .teamProfle i{font-size: 20px; color:#fff; }
            .teamProfle:hover{background-color: #14ba67;}
            

            .team .card-body{padding-top:0px;}


            /*  subscribe */


            .subscribe{padding: 30px 0px; background: rgb(15,137,206);
background: linear-gradient(90deg, rgba(15,137,206,1) 0%, rgba(15,208,111,1) 100%);}
.subscribe h5{font-size :18px; color: #fff; letter-spacing: 1px; font-weight: 500;}
.subscribe input[type=email]{background-color: rgba(8, 69, 99, 0.19);
    padding: 15px 22px; max-width: 600px;
    vertical-align: top;  border-radius: 4px 0px 0px 4px;
    border:1px solid rgba(255, 255, 255, 0.2);  width: calc(100% - 77px); color:#fff;  font-size: 16px;}
    .subscribe button{ padding: 5px 27px; background-color: #fff; font-size:30px; border:0px solid #fff;
vertical-align: top;  border-radius: 0px 4px 4px 0px; color:#0fb98e;
    }

    .subscribe button:hover{ background-color: #021f2e;}

::-webkit-input-placeholder{color: #fff; width: 100%;}

/*subfooter*/
.subfooter{
background-color:#253A7D;
padding: 40px 40px 0px;
}

.logo-col img{ width: 100%; max-width: 180px;}
.logo-col p{ max-width: 180px; color:#fff; margin-top:15px;}

.subfooter h5{ color: #fff; font-weight: 500; font-family: 'Carrois Gothic', sans-serif; margin-bottom: 15px;}
.footerLink a{ display:block; color:#eef4f7; margin-bottom:5px; font-family: 'Roboto Condensed', sans-serif;}
.footerLink a:hover{color:#0b9b48;}

.usefull-link{margin-top: 20px;}
.usefull-link a{  display: inline-block;
    font-size: 15px;
    color: #f6f5f5 !important;
    margin-bottom: 5px;
    font-family: 'Carrois Gothic', sans-serif;
    margin-right: 5px;
    border-right: 1px solid rgba(0, 0, 0, 0.2);
    padding-right: 8px;}
      .usefull-link a:hover{color: #0b9b48;}
      .usefull-link a:last-child{ border: 0;}
/* page content */
.pageContent{ background-color: #f1edf7; 
background-image: url(../images/pageContent-bg.svg);
background-position: center bottom;
background-repeat: no-repeat; background-size: 100% auto;
}

.contentWraper{background-color: #fff;
border-radius:10px;
    min-height: 400px;
    padding: 40px 40px;
    position: relative;
   
    top: -50px;
    box-shadow: 0px -10px 10px  rgba(0, 0, 0, 0.1);
    border-top:5px solid #00f0ff ;
}

/* mobile view */
@media (max-width:1199px){
    .heading-big {
        font-size: 34px;
        margin-bottom: 20px;
    }
    .home-banner p {
        font-size: 16px;
    }
    .navbar-expand-md .navbar-nav .dropdown-menu::before {display: none;}

    .navbar-expand-md .navbar-nav .user .dropdown-menu {
        margin-left:inherit;
    }

    .navbar-expand-md .navbar-collapse {
      
        display: contents !important;
        flex-basis: auto;
    }
 button.menu{ display:block;}
    .navbar-brand img{    width:180px;}
    ul.navbar-nav{
        flex-direction: column !important;
        background-color:#253a7d ;
        position: absolute;
        right: -275px;
        top: 0;
        height: 100vh;
        padding-top: 69px;
        overflow: auto;
        padding-right: 30px;
        padding-bottom: 50px;
        padding-left: 30px;
        width:250px;
        box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
        transition: all 300ms ease-out;
    }
    ul.navbar-nav .nav-link{
        color:#fff !important;
    }
    .login-link{display: inline;}
       .nav-item{ display: block; text-align: left; 
        border-top: 1px solid rgba(0,0,0,0.05);
        border-bottom: 1px solid rgba(255,255,255,0.1);
     }
     .nav-item:nth-child(1){border-top: 0;}
     .nav-item:last-child{ padding-top: 10px; border-bottom: 0;}
   

    ul.navbar-nav.activemenu{   right: 0px;  }

 
    .login-li{  margin: 5px 0px;}
    .navbar-nav .nav-item a.nav-link {
        padding: 0.5em 0.7em!important;
    } 
    a.nav-link span{ display: none !important;}  
    .navbar-expand-md .navbar-nav .dropdown-menu {
        margin-top: 0px;
        margin-bottom: 10px;
        position: initial;
        text-align: left;
        left:inherit;
        border-radius: 5px;
        background-color:transparent;
       box-shadow:none;
        border: 0;
    } 

    .navbar-expand-md hr{ display: none;}

    .dropdown-item {
        text-align:left;
    }

    .dropdown-item:focus, .dropdown-item:hover{
        background-color: transparent;
    }


   
}



@media (max-width:992px){

    .home-banner{padding-bottom: 0px;}
    .home-banner .d-flex {
       
        display: block !important;
    }
    .box { display: inline-block;
    
        padding: 12px 15px;
    }
   
    .goDownWraper {
        height: 75px;
    }
    .goDown {
        left:24%;
        width: 120px;
        height: 120px;
    }
    .goDown i {
top: 10%;
font-size: 45px;
left: 15%;
    } 
    .loginInput input{ max-width: 184px;}
    .footerCon {
        font-size: 12px;
    }

    .headingtype2 .main-heading {display: block;}
    .headingtype2 .main-heading::after {

        background: rgb(15,137,206);
        background: linear-gradient(90deg, rgba(15,137,206,1) 0%, rgba(15,208,111,1) 100%);
        position: relative;
        height: 5px;
        width: 50px;
        display: block;
        margin-top: 10px;
    }
}



@media (max-width:768px){
   
    .succces-story .main-heading {
        margin-bottom: 0;
    }
    .succces-story:before {
        top: 0;
        left: 0;
    }

    #testimonials {
        margin-top: 0px;
    }
    .box {
        display: inline;
        padding: 20px 15px;
        font-size: 14px;
    }
    .succces-story .gradiant{display:none;}
    #testimonials .imgcol .gradiant{display: block ; height: 5px;
        width: 78px;
    }

    .storyName {
        text-align: center;
    }

    .content-middle  .content-box{ padding-top: 20px; }
    

    .white-link {
        margin-bottom: 11px;
    }
   
    .footerCon {
        font-size: 12px;
        text-align: center !important;
        line-height: 30px;
    }
    .heading-samall{    font-size: 22px;}
    .formLnks a {
        margin: 0px 5px;
        font-size: 14px;
    }
    .loginInput input {
        height: 40px;
        margin-bottom: 10px;
        width: 100%;
       margin-right:0;
    }
    .loginInput input[type=submit] {
        width: 100%;
    }
    .navbar-brand img{    width:110px;}
    .shrink .navbar-brand img {
        width: 100px;
    }
    .navbar {
        padding: 0.5rem 1rem;
    }
    .page-banner{height: auto;
      
        background-position:30% center;
        background-size:auto 100%;
           }

           .rspnvAlingnright{ text-align: center !important; }

    p {
        font-size: 15px;
    
    }

  

    .goDownWraper{display: none;}
    .footerCon .text-left,  .footerCon .text-right{ text-align: center !important;}
    .home-banner {
        padding-top:60px;
        padding-bottom: 31px;
 }
 .my-links {
    font-size: 14px;
    padding: 13px 20px;
}
.my-links.blue {
    margin-right: 7px;
}

    .homeBody{overflow:auto;}

    .mobileImge{display: block; max-width: 300px;}
    .desktopBanner{display: none;}
    .heading-big {
    font-size:20px;

    line-height: 1.2;
    letter-spacing: 0;
    max-width: 625px;
    }
    .heading-big span{font-size: 30px;}

    /*login-page*/
    .loginBox {
        width: 291px;
        padding: 25px;
        display: inline-block;
        background-color: #f0f1f1f5;
    }
    .inputBox {
        padding: 4px;
        margin-bottom: 6px;
    }

    .loginBTN, .gloginBTN {
        height:40px;
        margin-bottom: 6px;
}
.socil-media {
    height: 30px;
    font-size: 12px;
    width: 30px;
    line-height: 30px;

}
}
@media (max-width:667px){

    .content-section{padding: 40px 0px; }

    .innterPageBanner .itm-aln {
        padding-top: 9px;
    
    }

    .shrink button.menu {
        height: 40px;
    }
    .formLnks {
        color: rgba(255, 255, 255, 0.0);
        font-size: 5px;
    }
    .formLnks a{display: block;}

  
}

@media (max-width:575px){

   /* .home-banner{    background-color: #0a4d72;
        background-image: -moz-linear-gradient( 0deg, rgba(10,79,118,72) 0%, rgba(32,150,91,72) 100%), url(../images/mobile-banner.jpg); 
        background-image:-webkit-linear-gradient( 0deg, rgba(10,79,118,0.72) 0%, rgba(32,150,91,0.72) 100%), url(../images/mobile-banner.jpg); 
        background-image: -ms-linear-gradient( 0deg, rgba(10,79,118,72) 0%, rgba(32,150,91,72) 100%), url(../images/mobile-banner.jpg); padding-top: 25%; }
        .itm-aln {
            align-items: flex-start;
         
        }*/

    .call-box{display: none;}
    .globbal-link {
        border-radius: 4px;
        
        font-size: 15px;
        padding: 12px 15px;
    }
    
    .white-link {
        margin-bottom: 0px; margin-left: 0px; margin-top: 20px;
    }
    .itm-aln {
        width:100%;
      }

      .footerCon {
        padding-top: 0;
     
    }

  

}


 
